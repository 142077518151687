<template>
  <div>
    <holidaysview></holidaysview>
    <router-link :to="{name: 'dashboardHolidays'}" class="flix-html-a"><b-flix-icon icon="cogwheel"></b-flix-icon> {{ $tc('message.additionalSettings',) }}</router-link>
  </div>
</template>
<script>
export default {
  name: 'CalendarView',
  components: {
    holidaysview () { return import('@/components/holidays/holidays.vue') }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
